import CloudStorage from "../utils/cloudStorage";
import fire from "../utils/fire";
import { getStorage } from "firebase/storage";

let storage: CloudStorage | null = null;

const useStorage = () => {
    if (storage === null) {
        storage = new CloudStorage(getStorage(fire));
    }

    return storage;
};

export default useStorage;