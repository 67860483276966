import { useNavigate } from "react-router-dom";
import { Avatar, Box, Button, Checkbox, Container, CssBaseline, FormControlLabel, Grid, Link, TextField, Typography } from "@mui/material"
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useState } from "react";
import useDatabase from "../hooks/useDatabase";

const Login = () => {
    const DB = useDatabase();
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");

    const submit = () => {
        if (!email.trim()) {
            setEmailError("No email provided.");
            return;
        }
        if (!password.trim()) {
            setPasswordError("No password provided.");
            return;
        }

        setEmailError("");
        setPasswordError("");

        const auth = getAuth();
        signInWithEmailAndPassword(auth, email, password)
            .then(async () => {
                navigate("/");
            })
            //only runs if email and/or password are invalid or incorrect
            .catch(err => {
                switch (err.code) {
                    case "auth/invalid-email":
                        setEmailError("Invalid email provided.");
                        break;
                    case "auth/user-disabled":
                        setEmailError("User account disabled. Please contact TbT admins.");
                        break;
                    case "auth/user-not-found":
                        setEmailError("User not found.");
                        break;
                    case "auth/wrong-password":
                        setPasswordError(err.message);
                        break;
                    case "auth/too-many-requests":
                        setPasswordError("Too many login attempts have been made from this device! Please try again later.");
                        break;
                    default:
                        setPasswordError(err.message);
                        break;
                }
            })
    }

    return <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
            sx={{
                marginTop: 8,
                paddingTop: 6,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                Sign in
            </Typography>
            <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={ email }
                    onChange={ e => setEmail(e.target.value) }
                />
                <Typography variant="body2" color="red">{ emailError }</Typography>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={ password }
                    onChange={ e => setPassword(e.target.value) }
                />
                <Typography variant="body2" color="red">{ passwordError }</Typography>
                <FormControlLabel
                    control={ <Checkbox value="remember" color="primary" /> }
                    label="Remember me"
                />
                <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} onClick={ submit }>Sign In</Button>
                <Grid container>
                    <Grid item xs>
                        <Link href="#" variant="body2">Forgot password?</Link>
                    </Grid>
                    <Grid item>
                        <Link href="#" variant="body2">Don't have an account? Sign Up</Link>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    </Container>
}

export default Login;