import { PayloadAction, configureStore, createSlice } from "@reduxjs/toolkit"
import { User } from "firebase/auth";
import { IUser } from "./types";
import { useDispatch, TypedUseSelectorHook, useSelector } from "react-redux";

export interface ReduxState {
    fireUser: User | null;
    userInfo: IUser | null;
    userLoaded: boolean;
}

const getInitialState = (): ReduxState => ({
    fireUser: null,
    userInfo: null,
    userLoaded: false
});

export const mainSlice = createSlice({
    name: "main",
    initialState: getInitialState(),
    reducers: {
        setFireUser: (state, { payload }: PayloadAction<User | null>) => {
            state.fireUser = payload;
        },
        setUserInfo: (state, { payload }: PayloadAction<IUser | null>) => {
            state.userInfo = payload;
            state.userLoaded = true;
        },
        resetState: (state) => {
            return getInitialState();
        }
    },
});

const store = configureStore({
    reducer: {
        user: mainSlice.reducer
    }
});

export const { setFireUser, setUserInfo, resetState } = mainSlice.actions;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
