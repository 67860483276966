import { Database, Unsubscribe, get, getDatabase, onValue, ref, set } from "firebase/database";
import database, { AsyncDBResult, DBError, DBResult, DBSuccess } from "./database";
import { IUser, Listener } from "./types";
import { getRandomInt } from "./helpers";
import { Auth, getAuth } from "firebase/auth";
import { ERROR_NOT_LOGGED_IN, ERROR_USER_DOES_NOT_EXIST } from "./constants";

export const DEFAULT_USER: IUser = {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    createdAt: 0,
    updatedAt: 0,
    verificationPin: 99999,
    flags: 0,
    teams: [],
    managingTeams: [],
    linkedFencerIds: []
};

class COMMON_DB_BASE {
    public PREFIX = "/common";
    protected database: Database;
    protected auth: Auth;

    private unsubscribers: WeakMap<Listener<unknown>, Unsubscribe> = new WeakMap();

    public constructor(_db: Database, _auth: Auth) {
        this.database = _db;
        this.auth = _auth;
    }

    public createUser(uid: string, firstName: string, lastName: string, email: string): void {
        const now = Date.now();
        const newUser: IUser = {
            id: uid,
            firstName,
            lastName,
            email,
            createdAt: now,
            updatedAt: now,
            verificationPin: getRandomInt(10000, 99999),
            flags: 1,
            teams: [],
            managingTeams: [],
            linkedFencerIds: []
        }
        set(ref(this.database, `${this.PREFIX}/users/${uid}`), newUser);
    }

    public async getCurrentUserInfo(listener?: Listener<DBResult<IUser>>): AsyncDBResult<IUser> {
        if (listener) {
            return new Promise(async res => {
                if (this.auth.currentUser?.uid) {
                    const queryResults = await get(ref(this.database, `${this.PREFIX}/users/${this.auth.currentUser!.uid}`));
                    const val = queryResults.val();
                    const defaultedVal: IUser = { ...DEFAULT_USER, ...val };
                    defaultedVal.managingTeams = defaultedVal.managingTeams.filter(Boolean);
                    defaultedVal.teams = defaultedVal.teams.filter(Boolean);
                    const response = new DBSuccess(defaultedVal);
                    listener && listener(response);
                    res(response);
                } else {
                    const err = new DBError(ERROR_NOT_LOGGED_IN);
                    listener && listener(err);
                    res(err);
                }
            });
        } else {
            const v = await get(ref(this.database, `${this.PREFIX}/users/${this.auth.currentUser!.uid}`));
            const val = v.val();
            const defaultedVal: IUser = { ...DEFAULT_USER, ...val };
            defaultedVal.managingTeams = defaultedVal.managingTeams.filter(Boolean);
            defaultedVal.teams = defaultedVal.teams.filter(Boolean);
            return new DBSuccess(defaultedVal);
        }
    }

    public getUserInfo(userId: string): AsyncDBResult<IUser> {
        return new Promise(async res => {
            const queryResults = await get(ref(this.database, `${this.PREFIX}/users/${userId}`));
            const val: IUser = { ...DEFAULT_USER, ...queryResults.val() };
            if (!val) {
                return res(new DBError(ERROR_USER_DOES_NOT_EXIST));
            }
            val.teams = val.teams.filter(Boolean);
            res(new DBSuccess(val));
        });
    }

    public async deleteAccount(): AsyncDBResult<boolean> {
        const currentUser = this.auth.currentUser;
        if (!currentUser) return new DBError(ERROR_NOT_LOGGED_IN);
        await set(ref(this.database, `${this.PREFIX}/users/${currentUser!.uid}`), null);
        await currentUser.delete();
        return new DBSuccess(true);
    }

    // TODO: implement this
    public linkUserToFencer() {

    }

    public getUserList(listener?: Listener<DBResult<Record<string, IUser>>>): AsyncDBResult<Record<string, IUser>> {
        return new Promise(async res => {
            if (listener) {
                const l = v => {
                    const val: Record<string, IUser> = v.val();
                    for (const i in val) {
                        val[i] = { ...DEFAULT_USER, ...val[i] };
                    }
                    const response = new DBSuccess(val);
                    listener(response);
                    res(response);
                };
                const unsubscriber = onValue(ref(this.database, `${this.PREFIX}/users`), l);
                if (this.unsubscribers.has(listener)) {
                    // TODO: Add support for multiple listeners later
                    this.unsubscribers.get(listener)!();
                }
                this.unsubscribers.set(listener, unsubscriber);
            } else {
                const queryResults = await get(ref(this.database, `${this.PREFIX}/users`));
                const val = queryResults.val() || {};
                for (const i in val) {
                    val[i] = { ...DEFAULT_USER, ...val[i] };
                }
                res(new DBSuccess(val));
            }
        });
    }

    public stopListeningUserList(listener: Listener<Record<string, IUser>>) {
        const l = this.unsubscribers.get(listener);
        if (l) {
            l();
            this.unsubscribers.delete(l);
        }
    }
}

const COMMON_DB = new COMMON_DB_BASE(getDatabase(), getAuth());

export default COMMON_DB;