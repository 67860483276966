import DB from "../utils/database";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";

let db: DB | null = null;

const useDatabase = () => {
    if (db === null) {
        db = new DB("/auto", "/v2", "/college", getDatabase(), getAuth());
    }

    return db;
};

export default useDatabase;