import { Typography, TypographyProps } from "@mui/material";
import { styled } from "@mui/material/styles";

interface GroupNameTextProps extends TypographyProps {
    groupColor: string;
}

const GroupNameText = styled(Typography)<GroupNameTextProps>(({ groupColor }) => ({
    backgroundColor: groupColor,
    display: "inline-block",
    padding: "2px 8px",
    borderRadius: 4,
    fontSize: 18,
    fontFamily: "Lexend Deca"
}));

export default GroupNameText;