import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AppBar, Box, Button, Divider, Drawer, Icon, IconButton, List, ListItem, Toolbar, Typography } from "@mui/material";
import Menu from "@mui/icons-material/Menu";
import { useAppDispatch, useAppSelector } from "../utils/store";
import { getAuth } from "firebase/auth";

const Navigation = () => {
    const navigate = useNavigate();

    const dispatch = useAppDispatch();
    const userInfo = useAppSelector(s => s.user.userInfo);

    const [mobileOpen, setMobileOpen] = useState(false);

    const handleLogout = () => {
        const auth = getAuth();
        auth.signOut();
        dispatch({ type: "setAdminstrating", administrating: false });
        navigate("/");
    };

    const container = window !== undefined ? () => document.body : undefined;

    return <AppBar sx={{ height: "65px" }}>
        <Toolbar sx={{ justifyContent: "space-between" }}>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={ () => setMobileOpen(u => !u) }
                sx={{ mr: 2, display: { md: "none" } }}
            >
                <Menu />
            </IconButton>
            <Box
                sx={{
                    width: 300,
                    flexGrow: 0,
                    display: { xs: "none", md: "block" }
                }}
            >
                <Link to="/" style={{ textDecoration: "none", color: "white" }}>
                    <Box>
                        <Typography
                            variant="h6"
                            component="div"
                            fontFamily="Lexend Deca"
                        >
                            TbT Fencing
                        </Typography>
                    </Box>
                </Link>
            </Box>
            <Box sx={{ display: { xs: "none", md: "block" } }}>
                <Link to="/devices">
                    <Button sx={{ color: "white" }}>
                        Devices
                    </Button>
                </Link>
            </Box>
            <Box sx={{ width: 300, flexGrow: 0, textAlign: "right" }}>
                { userInfo && <span style={{ color: "#DDD" }}>Logged in as { userInfo.firstName } { userInfo.lastName }</span> }
                { userInfo ? (
                    <Button style={{ color: "white" }} onClick={ handleLogout }>Log out</Button>
                    ) : (
                    <div>
                        <Button style={{ color: "white" }} href="/login">Log In</Button>
                    </div>
                ) }
            </Box>
        </Toolbar>
        <Box component="nav">
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={() => setMobileOpen(false)}
                ModalProps={{
                    keepMounted: true // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: "block", md: "none" },
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: 200
                    }
                }}
            >
                <Box onClick={() => setMobileOpen(false)} sx={{ textAlign: "center" }}>
                    <Button href="/" sx={{ textTransform: "initial", my: 1 }}>
                        <Typography variant="h6" component="div" fontFamily="Lexend Deca">
                            TbT Fencing
                        </Typography>
                    </Button>
                    <Divider />
                    <List>
                        <ListItem>
                            <Button href="/devices">
                                Devices
                            </Button>
                        </ListItem>
                    </List>
                </Box>
            </Drawer>
        </Box>
    </AppBar>
}

export default Navigation;