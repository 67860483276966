import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyAAkVuCXMsS8NjlziOwWXXIyuDjRsHWkgk",
    authDomain: "touchbytouch-b336e.firebaseapp.com",
    databaseURL: "https://touchbytouch-b336e.firebaseio.com",
    projectId: "touchbytouch-b336e",
    storageBucket: "touchbytouch-b336e.appspot.com",
    messagingSenderId: "60901701060",
    appId: "1:60901701060:web:84181d6968af08f5cde091",
    measurementId: "G-EL8V69KHD8"
};

const fire = initializeApp(firebaseConfig);

export default fire;