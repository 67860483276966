import { useEffect, useState } from "react";
import { useParams } from "react-router";
import useStorage from "../hooks/useStorage";

const Logs = () => {
    const { id } = useParams();
    const storage = useStorage();

    const [logsText, setLogsText] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        storage.getDownloadUrl(`auto-logs/${id}-logs.txt`).then(async downloadUrlResult => {
            if (downloadUrlResult.status === "fail") {
                setLoading(false);
                return setLogsText(`An error occurred: ${downloadUrlResult.data}`);
            }

            const response = await fetch(downloadUrlResult.data)
            const text = await response.text();
            setLogsText(text);
            setLoading(false);
        });
    }, [id]);

    const displayedText = loading ? "Loading..." : logsText;

    return <pre>
        { displayedText }
    </pre>
};

export default Logs;