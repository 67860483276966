import { FirebaseStorage, FullMetadata, getDownloadURL, getMetadata, ref } from "firebase/storage";
import { AsyncDBResult, DBError, DBSuccess } from "./database";
import { FirebaseError } from "firebase/app";

class CloudStorage {
    protected storage: FirebaseStorage;

    constructor(storage: FirebaseStorage) {
        this.storage = storage;
    }

    public async getFileMetadata(path: string): AsyncDBResult<FullMetadata> {
        try {
            const fileRef = ref(this.storage, path);
            const metadata = await getMetadata(fileRef);
            return new DBSuccess(metadata);
        } catch (e) {
            return new DBError((e as Error).message);
        }
    }

    public async getDownloadUrl(path: string): AsyncDBResult<string> {
        try {
            const fileRef = ref(this.storage, path);
            const url = await getDownloadURL(fileRef);
            return new DBSuccess(url);
        } catch (e) {
            switch ((e as FirebaseError).code) {
                case "storage/object-not-found":
                    return new DBError(`Logs could not be found.`);
                default:
                    return new DBError((e as Error).message);
            }
        }
    }
}

export default CloudStorage;