import { Box, Container, Typography } from "@mui/material"
import { Link } from "react-router-dom";

const Home = () => {
    return <Container sx={{ height: "100%", width: "100%" }}>
        <Typography variant="h3" fontFamily="Lexend Deca" sx={{ paddingTop: "30px", textAlign: "center" }}>
            Touch by Touch Auto Viewer
        </Typography>
        <Box sx={{ maxWidth: 800, margin: "auto" }}>
            <Typography fontSize={20} sx={{ marginTop: 3 }}>
                Welcome to the configuration page for Touch by Touch Auto Scoring. If you are looking for meet results, school information, or scorekeeping, please visit the main site at <Link to="https://tbtfencing.com">tbtfencing.com</Link>.
            </Typography>
            <Typography fontSize={20} sx={{ marginTop: 3 }}>
                If you have purchased autoscorers and would like to access them, please <Link to="/login">log in</Link> to get started.
            </Typography>
            <Typography fontSize={20} sx={{ marginTop: 3 }}>
                If you would like to purchase autoscorers for yourself or your team, please contact <a href="mailto:tbt@redpandafencing.org">tbt@redpandafencing.org</a>
            </Typography>
        </Box>
    </Container>
}

export default Home;