import { Weapon } from "./types";

export const genRandomStr = () => (Math.random() * 1e40).toString(36);

export const genRandomColor = () =>
    `rgba(${getRandomInt(0, 100)}, ${getRandomInt(0, 230)}, ${getRandomInt(0, 255)}, ${getRandomInt(40, 80) / 100})`;

export const getRandomInt = (min: number, max: number) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
};

export const idxFromWeaponHs = (weapon: Weapon, idx: number) => {
    const roundOffset = Math.floor(idx / 3) * 9;
    const boutOffset = idx % 3;
    const weaponOffset = {
        Sabre: 0,
        Foil: 3,
        Epee: 6
    }[weapon];
    return roundOffset + weaponOffset + boutOffset;
}

export const weaponFromIdxHs = (idx: number): { weapon: Weapon, bout: number } => {
    const boutOffset = idx % 3;
    const roundOffset = Math.floor(idx / 9) * 3;
    const weapon = ["Sabre", "Foil", "Epee"][Math.floor((idx % 9) / 3)] as Weapon;

    return {
        weapon,
        bout: boutOffset + roundOffset
    }
}

export const formatFileSize = (bytes: number, decimals = 2): string => {
    const units = ["B", "KB", "MB", "GB", "TB", "PB"];

    let i = 0;
    
    for (i; bytes > 1024; i++) {
        bytes /= 1024;
    }

    return `${parseFloat(bytes.toFixed(decimals))} ${units[i]}`;
}